import { RouteLocationNormalized, RouteLocationRaw, useRouter } from 'vue-router'

import { useMutableWatchers } from '../hooks'
import { mfRouter } from '../utils'

export const useInitRouterRedirect = (
  isAllowed: ((to: RouteLocationNormalized, from: RouteLocationNormalized) => boolean | string | RouteLocationRaw),
) => {
  const router = useRouter()
  const { setWatchers } = useMutableWatchers()

  setWatchers([
    router.beforeEach((to, from) => {
      if (to.matched.length === 0) {
        mfRouter.push('pages/404')
        return false
      }

      const allowed = isAllowed(to, from)

      if (typeof allowed === 'object') {
        return allowed
      }

      if (typeof allowed === 'string') {
        mfRouter.push(allowed)

        return false
      }

      if (!allowed) {
        mfRouter.push('pages/403')

        return false
      }

      return true
    }),
  ])
}
